<template>
  <div class="automaticOrdersActivities">
    <div class="activityHeader">
      <h3>Activity</h3>
    </div>
    <div class="listOfActivities">
      <div class="activitiesLists">
        <div
          class="eachList"
          v-for="(order, index) in this.getScanOrderLogs.data"
          :key="index"
        >
          <div class="accessionId">
            <img
              src="@/assets/images/indicator.svg"
              alt="icon"
              class="blood-icon"
            />
            <h4>{{ order ? order.case_id.accession_id : "--" }}</h4>
          </div>
          <div class="reasonDes">
            <span>{{ order ? order.description + " for " : "--" }}</span>
            <span
              v-if="order && order.case_id && order.case_id.patient_info"
              style="color: -webkit-link"
              >{{
                order.case_id.patient_info
                  ? order.case_id.patient_info.first_name +
                    " " +
                    order.case_id.patient_info.last_name
                  : "--"
              }}</span
            >
          </div>
          <div class="activityTime" v-if="order && order.created_at">
            {{
              order.created_at
                ? momentWithTimezone(order.created_at, "MM-DD-yyyy hh:mm A")
                : "--"
            }}
          </div>
        </div>
      </div>

      <div class="listOfActivitiesPaginations">
        <el-pagination
          :small="small"
          v-model:currentPage="page"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 30, 50]"
          layout=" prev, pager, next, jumper, sizes ,total"
          :total="getScanOrderLogs.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ActivityContainer",
  data() {
    return {
      page: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapGetters("orders", ["getScanOrderLogs"]),
  },
  async mounted() {
    await this.fetchScanLogs();
  },
  methods: {
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchScanLogs();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchScanLogs();
    },
    async fetchScanLogs() {
      try {
        let params = {
          page: this.page,
          limit: this.pageSize,
        };
        await this.$store.dispatch("orders/fetchScanOrderLogs", params);
      } catch (err) {
        console.log(err);
      }
    },
  },
});
</script>
<style></style>
